import { createSlice } from "@reduxjs/toolkit";
import {
  bookmarkMail,
  getMail,
  removeBookmarkedMail,
  searchMail,
  uploadAttachment,
} from "./thunks/mails";
import toast from "react-hot-toast";

// const REACT_APP_API_URL = "http://localhost:4000";

// //console.log(process.env);

//initial state

const initialState = {
  total: 0,
  totalSent: 0,
  mail: {},
  attachments: [],
};

const inboxTestSlice = createSlice({
  name: "studentInbox",
  initialState: initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getMail.fulfilled, (state, action) => {
        state.mail = {
          ...action.payload.mail,
        };
        state.total = action.payload.total;
        state.totalSent = action.payload.totalSent;
      })
      .addCase(bookmarkMail.pending, (state, action) => {
        //console.log("bookmarkload");
      })
      .addCase(bookmarkMail.fulfilled, (state, action) => {
        //console.log("bookmarkf", action.payload);
      })
      .addCase(bookmarkMail.rejected, (state, action) => {
        //console.log("bookmarkr", action.payload);
      })
      .addCase(removeBookmarkedMail.pending, (state, action) => {
        //console.log("removebookmarkload");
      })
      .addCase(removeBookmarkedMail.fulfilled, (state, action) => {
        //console.log("removebookmarkf", action.payload);
      })
      .addCase(removeBookmarkedMail.rejected, (state, action) => {
        //console.log("removebookmarkr", action.payload);
      })
      .addCase(searchMail.fulfilled, (state, action) => {
        state.mail.emailsReceived = action.payload.emailsReceived;
        state.mail.emailsSent = action.payload.emailsSent;
        state.total = action.payload.total;
        state.totalSent = action.payload.total;
      })
      .addCase(uploadAttachment.fulfilled, (state, action) => {
        let atach = [];
        if (state.attachments) {
          atach = [...state.attachments];
        }
        state.attachments = [...atach, ...action.payload];
      });
  },
});

export const getInbox = (state) => {
  //console.log(state.inbox);
  const received = state.inbox.mail?.emailsReceived?.map((value) => {
    return { ...value, isChecked: false };
  });
  const sent = state.inbox.mail?.emailsSent?.map((value) => {
    return { ...value, isChecked: false };
  });
  return { received, sent };
};

//
export const {} = inboxTestSlice;
export default inboxTestSlice.reducer;
