import { configureStore } from "@reduxjs/toolkit";
import companyReducer from "../redux/features/company/companySlice.js";

import dashboardReducer from "../redux/student/dashboard/dashboardSlice.js";

import studentSideReducer from "../redux/student/sidebar/sideSlice.js";

import studentAuthReducer from "./student/auth/studentAuthSlice.js";
import studentTestReducer from "./student/test/studentTestSlice.js";
import inboxTestReducer from "./student/inbox/inboxTestSlice.js";
import resultReducer from "../redux/student/result/resultSlice.js";
import assessmentReducer from "./student/assessment/assessmentSlice.js";
const store = configureStore({
  reducer: {
    // ----------------- STUDENT -----------------

    studentSide: studentSideReducer,
    studentAuth: studentAuthReducer,
    dashboard: dashboardReducer,
    company: companyReducer,
    inbox: inboxTestReducer,
    studentTest: studentTestReducer,
    results: resultReducer,
  },
});

export default store;
