import { createSlice } from "@reduxjs/toolkit";

import toast from "react-hot-toast";
import {
  endTest,
  getAllAssessments,
  getAssessmentById,
  getLog,
  sendResponse,
  sendResponseNonAdaptive,
  startTest,
  uploadScreenshot,
} from "./thunks/assessment";
import { exitFullscreen } from "../../../util/requestFullScreen";

// const REACT_APP_API_URL = process.env.REACT_APP_API_URL;
// const REACT_APP_API_URL = "http://localhost:4000";

// //console.log(process.env);

//initial state

const getLocalStorageItem = (
  key,
  returnNumber = false,
  returnOther = "NON_INITIALIZED_VALUE"
) => {
  const item = localStorage.getItem(key);
  try {
    if (returnNumber) {
      return item ? JSON.parse(item) : 1;
    }
    if (returnOther !== "NON_INITIALIZED_VALUE") {
      return item ? JSON.parse(item) : returnOther;
    }
    return item ? JSON.parse(item) : {};
  } catch (e) {
    console.warn(`Error parsing localStorage item for key "${key}":`, e);
    return {};
  }
};

const studentTestState = {
  logs: [],
  testTimer: 0,
  timeTaken: localStorage.getItem("timeTaken")
    ? JSON.parse(localStorage.getItem("timeTaken"))
    : 0,
  intervalId: null,
  assessments: [],
  currentAssessment: {},
  topicNames: [],
  totalQuestions: 0,
  currentTopicIndex: 0,
  adaptive: {
    isLast: getLocalStorageItem("isLast", false, false),
    count: getLocalStorageItem("currentQuestionCount", true),
    question: getLocalStorageItem("question"),
    topic: getLocalStorageItem("topic"),
    topicName: "",
  },
  nonAdaptive: {
    isLast: getLocalStorageItem("isLast", false, false),
    count: getLocalStorageItem("currentQuestionCount", true),
    test: getLocalStorageItem("topic"),
    question: getLocalStorageItem("question"),
    topicIndex: 0,
    questionIndex: 0,
    topic: getLocalStorageItem("topic"),
    topicName: "",
    topicType: "",
  },
  START_TEST_LOADING: false,
  NEXT_QUESTION_LOADING: false,
  END_TEST_LOADING: false,
  GET_ALL_TESTS_LOADING: false,
  GET_TEST_LOADING: false,
  TEST_COMPLETED: false,

  submitTest: false,
};

const studentTestSlice = createSlice({
  name: "studentTest",
  initialState: studentTestState,
  reducers: {
    setTimerTest: (state) => {
      //console.log("setTimerTest called");
      clearInterval(state.intervalId);
      state.timeTaken = 0;
    },
    updateTimer: (state) => {
      state.timeTaken += 1;
      localStorage.setItem("timeTaken", state.timeTaken);
    },
    setIntervalId: (state, action) => {
      state.intervalId = action.payload;
    },
    clearTimer: (state) => {
      clearInterval(state.intervalId);
      state.timeTaken = 0;
      state.intervalId = null;
    },
    setSubmitTest: (state, action) => {
      state.submitTest = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder

      // ------------------------------------ startTest ------------------------------------
      .addCase(startTest.pending, (state, action) => {
        state.START_TEST_LOADING = true;
      })
      .addCase(startTest.fulfilled, (state, action) => {
        toast.success("starting test");

        state.START_TEST_LOADING = false;
        state.topicNames = action.payload.topicNames;
        localStorage.setItem("timer", JSON.stringify(action.payload.totalTime));
        // //console.log(action.payload,"timer")
        state.currentTopicIndex = 0;

        localStorage.setItem("isLast", JSON.stringify(action.payload.isLast));
        localStorage.setItem(
          "topicNames",
          JSON.stringify(action.payload.topicNames)
        );
        localStorage.setItem(
          "totalQuestions",
          JSON.stringify(action.payload.totalQuestions)
        );
        localStorage.setItem("currentTopicIndex", JSON.stringify(0));

        //console.log(action.payload, "payload");

        // if (action.payload.isAdaptive === "true") {
        if (action.payload.isAdaptive === "true") {
          if (!action.payload.firstQuestion) {
            //console.log("no next question found");
            window.location.href = `/student/test/${action.payload.testId}/start?isAdaptive=true`;
            return;
          }
        } else {
          if (!action.payload.firstQuestion) {
            //console.log("no next question found");
            // window.location.href = `/student/test/${action.payload.testId}/start?isAdaptive=false`;
            // return;
          }
        }
        const isAdaptive = action.payload.isAdaptive === "true";
        // //console.log(isAdaptive, "isAdaptive");

        if (isAdaptive) {
          state.adaptive.question = action.payload.firstQuestion;
          state.adaptive.topic = action.payload.topic;

          localStorage.setItem("topic", JSON.stringify(action.payload.topic));
          localStorage.setItem(
            "question",
            JSON.stringify(action.payload.firstQuestion)
          );
          localStorage.setItem("currentQuestionCount", 1);
          window.location.href = `/student/test/${action.payload.testId}/start?isAdaptive=true`;
        } else {
          state.nonAdaptive.question = action.payload.firstQuestion;

          state.nonAdaptive.topic = action.payload.topic;
          localStorage.setItem("topic", JSON.stringify(action.payload.topic));
          localStorage.setItem(
            "question",
            JSON.stringify(action.payload.firstQuestion)
          );
          localStorage.setItem("currentQuestionCount", 1);

          window.location.href = `/student/test/${action.payload.testId}/start?isAdaptive=false`;
        }
      })
      .addCase(startTest.rejected, (state, action) => {
        state.START_TEST_LOADING = false;

        toast.error(action.payload);
      })
      // ------------------------------------ End Test --------------------------------------

      .addCase(endTest.pending, (state, action) => {
        state.END_TEST_LOADING = true;
      })
      .addCase(endTest.fulfilled, (state, action) => {
        // toast.success("Test completed");
        state.END_TEST_LOADING = false;
        state.TEST_COMPLETED = true;
        state.START_TEST_LOADING = false;
        localStorage.removeItem("isLast");
        localStorage.removeItem("timeTaken");
        // window.location.href = `/student/test`;
      })
      .addCase(endTest.rejected, (state, action) => {
        // toast.error("Test not completed");
        toast.error(action.payload);
        state.END_TEST_LOADING = false;
      })

      // ------------------------------------ sendResponse ------------------------------------
      .addCase(sendResponse.pending, (state, action) => {
        state.NEXT_QUESTION_LOADING = true;
        state.TEST_COMPLETED = false;
      })
      .addCase(sendResponse.fulfilled, (state, action) => {
        state.NEXT_QUESTION_LOADING = false;
        if (action.payload.nextQuestion) {
          state.adaptive.question = action.payload.nextQuestion || {};
          state.adaptive.topic = action.payload.topic || {};
          state.adaptive.topicName = action.payload.topic?.Heading || "";
          //console.log(action.payload.topicName, "nextQuestion TopicName");
          state.adaptive.count = action.payload.count || 1;
          localStorage.setItem(
            "currentQuestionCount",
            action.payload.count || 1
          );
          state.adaptive.isLast = action.payload.isLast || false;
          localStorage.setItem("isLast", JSON.stringify(action.payload.isLast));
          state.currentTopicIndex = action.payload.topicIndex || 0;
        } else {
          // toast.success("Test completed");
          // window.location.href = `/student/test`;
          exitFullscreen();
          state.TEST_COMPLETED = true;
        }
      })
      .addCase(sendResponse.rejected, (state, action) => {
        state.NEXT_QUESTION_LOADING = false;
      })
      // ------------------------------------ sendResponseNonAdaptive ----------------------------
      .addCase(sendResponseNonAdaptive.pending, (state, action) => {
        state.NEXT_QUESTION_LOADING = true;
        state.TEST_COMPLETED = false;
      })

      .addCase(sendResponseNonAdaptive.fulfilled, (state, action) => {
        state.NEXT_QUESTION_LOADING = false;

        if (action.payload.nextQuestion) {
          state.nonAdaptive.question = action.payload.nextQuestion || {};
          //console.log(action.payload.topic, "nextQuestion");
          state.nonAdaptive.topic = action.payload.topic || {};
          state.nonAdaptive.topicName = action.payload.topicName || "";
          state.nonAdaptive.topicType = action.payload.topicType || "";
          state.nonAdaptive.count = action.payload.count || 1;
          state.nonAdaptive.isLast = action.payload.isLast || false;
          localStorage.setItem("isLast", JSON.stringify(action.payload.isLast));
          state.currentTopicIndex = action.payload.topicIndex || 0;
          localStorage.setItem(
            "currentQuestionCount",
            action.payload.count || 1
          );
          localStorage.setItem("topic", JSON.stringify(action.payload.topic));
          localStorage.setItem(
            "question",
            JSON.stringify(action.payload.nextQuestion)
          );
        } else {
          // toast.success("Test completed");
          state.TEST_COMPLETED = true;
          exitFullscreen();
          // window.location.href = `/student/test`;
        }
      })
      .addCase(sendResponseNonAdaptive.rejected, (state, action) => {
        state.NEXT_QUESTION_LOADING = false;
      })
      // ------------------------------------ getAllAssessments ----------------------------------
      .addCase(getAllAssessments.pending, (state, action) => {
        //console.log(action.payload);
        state.GET_ALL_TESTS_LOADING = true;
        // state.assessments = action.payload;
      })
      .addCase(getAllAssessments.fulfilled, (state, action) => {
        //console.log(action.payload);
        state.assessments = action.payload;
        state.GET_ALL_TESTS_LOADING = false;
      })

      .addCase(getAllAssessments.rejected, (state, action) => {
        //console.log(action.payload);
        state.GET_ALL_TESTS_LOADING = false;
        // state.assessments = action.payload;
      })
      // ------------------------------------ getAssessmentById ----------------------------------
      .addCase(getAssessmentById.pending, (state, action) => {
        state.GET_TEST_LOADING = true;
        //console.log("pending getAssessmentById");
      })
      .addCase(getAssessmentById.fulfilled, (state, action) => {
        //console.log(action.payload);
        state.GET_TEST_LOADING = false;
        state.currentAssessment = action.payload;
      })
      .addCase(getAssessmentById.rejected, (state, action) => {
        state.GET_TEST_LOADING = false;
        //console.log("rejected getAssessmentById");
      })
      .addCase(getLog.fulfilled, (state, action) => {
        state.logs = action.payload.logs;
      })
      .addCase(uploadScreenshot.fulfilled,(state,action)=>{
        
      });
  },
});

//
export const { setTimerTest, updateTimer, setSubmitTest } =
  studentTestSlice.actions;
export default studentTestSlice.reducer;
