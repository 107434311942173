import React from 'react';
import { Link } from 'react-router-dom';

const ThankYouPage = () => {

  const handleRedirect = () => {
    window.location.reload();
    window.location.href = "/student/test";
  };
  return (
    <div className="min-h-screen bg-gradient-to-r from-blue-50 to-blue-100 flex flex-col justify-center items-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-screen-sm text-center">
      <div className="bg-white py-24 px-4 shadow-lg rounded-lg sm:px-10">
        <h2 className="text-4xl font-bold text-gray-800">
          Thank You! 🎉
        </h2>
        <p className="mt-4 text-xl text-gray-600">
          Your test has been successfully submitted. We will review your results and get back to you shortly.
        </p>
  
      <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-md">
   
          <button
            onClick={handleRedirect}
            className="w-full flex justify-center items-center px-6 py-3 border border-transparent text-lg font-medium rounded-md text-white bg-accent hover:bg-accent focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-400"
          >
            Return to Homepage
          </button>
        </div>
        </div>
      </div>
    </div>
  );
};

export default ThankYouPage;
