import React, { useEffect } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Loader from "../../../components/loaders/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
  getAssessmentById,
  startTest,
} from "../../../redux/student/test/thunks/assessment";
import useTranslate from "../../../hooks/useTranslate";
import { FaAngleLeft } from "react-icons/fa";
import { toast } from "react-hot-toast";

const Instruction = () => {
  // useTranslate();
  const navigate = useNavigate();
  const { currentAssessment, START_TEST_LOADING } = useSelector(
    (state) => state.studentTest
  );
  const { id } = useParams();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const isAdaptive = searchParams.get("isAdaptive");

  const startAssess = async () => {
    const getCameraAccess = async () => {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({ video: true });
        // Stop the video stream immediately after getting access
        stream.getTracks().forEach(track => track.stop());
        
        // Camera access granted, start the test
        dispatch(startTest({ 
          testId: id, 
          isAdaptive: searchParams.get("isAdaptive") === "true"
        }));
        
        toast.success("Camera access granted. Test starting...");
      } catch (error) {
        console.error("Camera access error:", error);
        toast.error("Camera access is required to start the test.");
        navigate(-1);
      }
    };

    try {
      await getCameraAccess();
    } catch (error) {
      console.error("Failed to start assessment:", error);
      toast.error("An error occurred while starting the test. Please try again.");
      navigate(-1);
    }
  };



  useEffect(() => {
    dispatch(getAssessmentById(id));
  }, [dispatch, id]);

  const assessment = currentAssessment?.assessment;
  const questionTypes = {
    mcq: 0,
    compiler: 0,
    essay: 0,
    findAnswer: 0,
    video: 0,
  };

  let adaptiveNumber = 0;
  assessment?.topics?.forEach((topic) => {
    adaptiveNumber += topic?.totalL1Question || 0;
    switch (topic?.Type) {
      case "mcq":
        questionTypes.mcq +=
          topic.totalL1Question ||
          topic.totalL2Question ||
          topic.totalL3Question ||
          0;
        // questionTypes.mcq += topic.questions?.length || 0;
        break;
      case "compiler":
        questionTypes.compiler += topic.compiler?.length || 0;
        break;
      case "findAnswer":
        questionTypes.findAnswer += topic.findAnswers?.length || 0;
        break;
      case "essay":
        questionTypes.essay += topic.essay?.length || 0;
        break;
      case "video":
        questionTypes.video += topic.video?.length || 0;
        break;
      default:
        break;
    }
  });

  const numberCount =
    questionTypes.mcq +
    questionTypes.compiler +
    questionTypes.essay +
    questionTypes.findAnswer +
    questionTypes.video;

  return (
    <>
      <div className="flex  mx-auto justify-between mb-5 font-dmSans">
        <div className="flex gap-4">
          <button
            className="bg-gray-200  self-center rounded-lg h-10 w-10 sm:h-12 sm:w-16"
            onClick={() => navigate(-1)}
          >
            <FaAngleLeft className="mx-auto sm:h-6 sm:w-6 h-4 w-4" />
          </button>
          <h2 className="text-xl font-bold self-center">Test Instructions</h2>
        </div>
      </div>

      <div className="bg-white rounded-lg p-5 w-full flex gap-5">
        <div className="w-1/2">
          {" "}
          <h2 className="text-xl font-semibold mb-2">Warnings</h2>
          <ul className="list-disc list-inside mb-4">
            <li>No cheating.</li>
            <li>Do not refresh the page.</li>
            <li>Make sure you have a stable internet connection.</li>
            <li>Do not use any external resources.</li>
            <li>Ensure your device is fully charged or plugged in.</li>
            <li>Stay in full-screen mode during the test.</li>
            <li>Do not open any other tabs or applications.</li>
            <li>Read all questions carefully before answering.</li>
          </ul>
        </div>
        <div className="w-1/2">
          <p className="mb-4">
            <strong>Number of Questions:</strong>{" "}
            {isAdaptive === "false" ? numberCount : adaptiveNumber}
          </p>
          <p className="mb-4">
            <strong>Types of Questions:</strong>{" "}
            {isAdaptive === "false"
              ? Object.keys(questionTypes)
                  .filter((key) => questionTypes[key])
                  .map((key, index) => (
                    <span key={key}>
                      {index > 0 && ", "}
                      {key.charAt(0).toUpperCase() + key.slice(1)}
                    </span>
                  ))
              : "Mcq"}
          </p>
          {isAdaptive === "true" && (
            <p className="mb-4">
              <ul className="list-disc list-inside mb-4">
                <li>There are three levels of questions: L1, L2, and L3.</li>
                <li>
                  To advance to the next level, you must correctly answer at
                  least one-third of the questions.
                </li>
                <p className="ml-4">
                  <span className="font-bold">For example :</span> If there are
                  a total of 5 questions, you need to answer 2 questions
                  correctly at L1 to advance to L2. Similarly, you must answer 2
                  questions correctly at L2 to reach L3. If you fail to answer 2
                  questions correctly at L1, you will remain at L1 for all 5
                  questions,if you correct 3rd and 4th L1 then you will get 5th
                  as L2.
                </p>
              </ul>
            </p>
          )}
          {isAdaptive === "false" && (
            <p className="mb-4">
              <strong>Time:</strong> {assessment?.totalTime} mins
            </p>
          )}{" "}
          <div>
            <button
              onClick={startAssess}
              className="bg-accent text-white px-5 py-1 rounded hover:bg-accent inline-flex items-center justify-center"
            >
              {START_TEST_LOADING ? "Test Starting" : "Start"}
              {START_TEST_LOADING && <Loader />}
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Instruction;
