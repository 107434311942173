import React from "react";
import { FaChevronLeft } from "react-icons/fa";
import { PiSlidersHorizontalLight } from "react-icons/pi";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="flex justify-between font-dmSans">
        <button
          className="bg-[#D9E1E7] rounded-lg h-10 w-10 sm:h-12 sm:w-14"
          onClick={() => navigate(-1)}
        >
          <FaChevronLeft className="mx-auto  h-4 w-4 font-light" />
        </button>
      </div>
      <div className="object-fill rounded-2xl ">
        <img
          src="../../images/overview.png"
          alt="overview"
          className="w-full"
        />
      </div>
    </>
  );
};

export default Header;
