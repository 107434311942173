import { lazy } from "react";
import { Route } from "react-router-dom";
import TestOverview from "./TestOverview";
import TestHome from "./TestHome";
const TestPage = lazy(() => import("./TestPage"));
const TestNonAdaptivePage = lazy(() => import("./TestNonAdaptivePage"));

const StudentTestRoute = () => {
  return (
    <>
      <Route path="student/test" element={<TestPage />} />
      <Route
        path="student/nonAdaptiveTest/:id/start"
        element={<TestNonAdaptivePage />}
      />
      <Route path="student/test/section/:id" element={<TestHome />} />
      <Route path="student/test/:id" element={<TestOverview />} />
    </>
  );
};

export default StudentTestRoute;
