import React, { useState } from "react";
import Skeleton from "../../../loaders/Skeleton";

const Video = ({ question, count, setAnswer, answer, loading }) => {
  const handleLongAnswerChange = (e, index) => {
    const { value } = e.target;
    const updatedLongAnswers = [...answer.long];
    updatedLongAnswers[index] = value;
    setAnswer({ ...answer, long: updatedLongAnswers });
  };

  const handleShortAnswerChange = (e, index) => {
    const { value } = e.target;
    const updatedShortAnswers = [...answer.short];
    updatedShortAnswers[index] = value;
    setAnswer({ ...answer, short: updatedShortAnswers });
  };

  const handleMcqChange = (e, index) => {
    const { value } = e.target;
    const updatedMcqAnswers = [...answer.mcq];
    updatedMcqAnswers[index] = value;
    setAnswer({ ...answer, mcq: updatedMcqAnswers });
  };
  // //console.log(question)

  return (
    <>
      {loading ? (
        <Skeleton />
      ) : (
        question?.videoFile && (
          <div className="mx-6 flex bg-[#F8F8F9] rounded-lg justify-between my-4">
            <div className="w-11/12 flex flex-col gap-2">
              <div className="px-5 pb-4 flex flex-col gap-4 pt-2">
                <video width="100%" controls>
                  <source src={question.videoFile} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>

                {/*  -------------------------------- LONG ANS ------------------------------------- */}

                {question?.long && question?.long?.length > 0 && (
                  <h1 className="flex px-4 gap-3 pt-3 uppercase text-base w-full font-extrabold ">
                    Long Answer Questions
                  </h1>
                )}

                {question?.long &&
                  question?.long?.map((ques, index) => (
                    <div>
                      <h2 className="flex px-4 gap-3 pt-3 text-base w-full font-semibold">
                        <span className=" font-bold">{index + 1})</span>
                        <div
                          dangerouslySetInnerHTML={{ __html: ques.Title }}
                        ></div>
                      </h2>

                      <textarea
                        key={index}
                        className="resize-none w-full h-full text-lg bg-gray-100 border-none focus:outline-none mb-3 rounded-lg focus:ring-0 placeholder-gray-400"
                        placeholder="Enter Answer Here"
                        name="studentAnswer"
                        value={answer?.long[index] || ""}
                        onChange={(e) => handleLongAnswerChange(e, index)}
                      ></textarea>
                    </div>
                  ))}

                {/* --------------------------------------------  SHORT ANS ------------------------------------------------ */}

                {question?.short && question?.short?.length > 0 && (
                  <h1 className="flex px-4 gap-3 uppercase text-base w-full font-extrabold ">
                    Short Answer Questions
                  </h1>
                )}
                {question?.short &&
                  question?.short.map((ques, index) => (
                    <div>
                      <h2 className="flex px-4 gap-3 pt-3 text-base w-full font-semibold">
                        <span className=" font-bold">{index + 1})</span>
                        <div
                          dangerouslySetInnerHTML={{ __html: ques.Title }}
                        ></div>
                      </h2>
                      <input
                        key={index}
                        type="text"
                        className="w-full text-lg bg-gray-100 border-none focus:outline-none mb-3 rounded-lg focus:ring-0 placeholder-gray-400"
                        placeholder="Enter Short Answer Here"
                        name="studentAnswer"
                        value={answer?.short[index] || ""}
                        onChange={(e) => handleShortAnswerChange(e, index)}
                      />
                    </div>
                  ))}

                {/* ------------------------------------------ MCQ ----------------------------------------------------------- */}
                {question.questions && question.questions.length > 0 && (
                  <h1 className="flex px-4 gap-3 uppercase text-base w-full font-extrabold ">
                    MULTIPLE CHOICE Questions
                  </h1>
                )}
                {question?.questions &&
                  question?.questions.map((ques, questionIndex) => (
                    <div key={questionIndex}>
                      <h2 className="flex px-4 gap-3 pt-3 text-base w-full font-semibold">
                        <span className=" font-bold">{questionIndex + 1})</span>
                        <div
                          dangerouslySetInnerHTML={{ __html: ques.Title }}
                        ></div>
                      </h2>
                      {ques.Options.map((option, optionIndex) => (
                        <span className="flex gap-2" key={optionIndex}>
                          <div className="flex w-12 justify-center pl-8 ">
                            <input
                              onChange={(e) =>
                                handleMcqChange(e, questionIndex)
                              }
                              checked={
                                parseInt(answer.mcq[questionIndex]) ===
                                optionIndex
                              }
                              name={`option-${questionIndex}`}
                              value={optionIndex}
                              type="radio"
                              id={`answer-${questionIndex}-${optionIndex}`}
                              className="w-3 h-3 p-[.4rem] checked:bg-none checked:border checked:border-blue-700 border-blued checked:p-0 border-2 ring-transparent ring-2 checked:ring-blue-700 ring-offset-2 self-center"
                            />{" "}
                          </div>
                          <label
                            htmlFor={`answer-${questionIndex}-${optionIndex}`}
                            className="self-center"
                          >
                            {option}
                          </label>
                        </span>
                      ))}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default Video;
