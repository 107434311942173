import { Disclosure } from "@headlessui/react";
import React, { useState, useRef } from "react";
import toast from "react-hot-toast";
import { FaWindowMinimize } from "react-icons/fa";
import { FaCaretDown } from "react-icons/fa6";
import SkeletonLoader from "./SkeletonLoader";

const SlideUpDown = ({
  runLoading,
  runTestLoading,
  answer,
  testcase,
  question,
  log,
  setLog,
  userInput,
  setUserInput,
  userOutput,
  setUserOutput,
  loading,
  toggle,
  handleTransitionEnd,
  divRef,
  isOpen,
  width,
  setWidth,
  panel,
  userTheme,
}) => {
  // if (runLoading || runTestLoading) {
  //   return <SkeletonLoader />;
  // }
  return (
    <div
      className="w-full"
      style={{
        width: width || "500px",
      }}
    >
      {/* <button
        className="bg-accent hover:bg-accent text-white font-bold py-2 px-4 rounded"
        onClick={toggle}
      >
        Toggle
      </button> */}
      <div
        onMouseOver={() => {
          // toast.success("over slide");
          setWidth(panel.current.offsetWidth);
        }}
        ref={divRef}
        style={{ height: isOpen ? divRef.current?.scrollHeight : "0px" }}
        className={`overflow-hidden transition-all duration-500  w-full ease-in-out ${
          isOpen
            ? "translate-y-0 -translate-y-[1rem] z-50 h-0"
            : "translate-y-full h-full"
        } `}
        onTransitionEnd={handleTransitionEnd}
      >
        <div
          className={`${
            userTheme === "vs-dark" ? "bg-[#1e1e1e]" : "bg-slate-100"
          }  min-h-[65vh] `}
          onMouseOver={() => {
            // toast.success("over slide");
            setWidth(panel.current.offsetWidth);
          }}
        >
          {/* {runLoading === false && runTestLoading === false ? ( */}
          <div className="p-4  gap-2">
            {/* input and test cases */}
            <span className="w-1/2 py-6 ">
              {/* <div className="p-4"> */}
              {/* <label htmlFor={`output-${1}`} className="font-bold">
              Expected Output:
            </label>
            <input
              type="text"
              id={`output-${1}`}
              className="rounded-lg p-1 w-full border-none bg-gray-100"
              name="expectedOutput"
              value={loading ? "Loading..." : userOutput}
              // value={testCase.expectedOutput.map((item) => (Array.isArray(item) ? `[${item.join(",")}]` : item)).join(", ")}
              // onChange={(e) => handleOutputChange(index, e.target.value)}
            /> */}
              {/* <button
              onClick={() => {
                clearOutput();
              }}
              className="clear-btn"
            >
              Clear
            </button> */}
              {/* </div> */}

              <div
                className={`w-full flex justify-between mb-2 ${
                  userTheme === "vs-dark" ? "text-gray-50" : ""
                }`}
              >
                <label htmlFor={`output-${1}`} className="font-bold">
                  <label htmlFor={`input-${1}`} className="font-bold py-4 my-1">
                    Input :-
                  </label>{" "}
                </label>
                <span
                  role="img"
                  aria-label="fail"
                  onClick={() => toggle()}
                  className="mr-1"
                >
                  <FaWindowMinimize />
                </span>
              </div>
              <textarea
                type="text"
                id={`input-${1}`}
                className={`h-[100px] rounded-lg p-1 w-full border  border-slate-300 focus:ring-0 focus:bg-slate-300 mb-2 ${
                  userTheme === "vs-dark" ? "bg-gray-200" : "b"
                }`}
                // value={testCase.input.map((item) => (Array.isArray(item) ? `[${item.join(",")}]` : item)).join(", ")}
                // onChange={(e) => handleInputChange(index, e.target.value)}
                name="input"
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
              />
            </span>
            <span className="w-1/2 py-6 h-full">
              <h2
                className={`font-bold mb-2 ${
                  userTheme === "vs-dark" ? "text-gray-50 " : ""
                }`}
              >
                Output :-
              </h2>
              <textarea
                value={userOutput || log}
                onChange={() => {}}
                name=""
                id=""
                className={`pointer-events-none w-full border-l-8 border-x-0 border-0 focus:ring-0 border-gray-400 focus:border-gray-400 h-32 resize-y ${
                  userTheme === "vs-dark" ? "bg-gray-200" : ""
                } `}
              ></textarea>
            </span>
            <h1 className={`${userTheme === "light" ? " " : "text-white "}`}>
              Total cases passed :{" "}
              {answer.totalTestCases + "/" + question.testcase.length}
            </h1>
            {testcase.length > 0 &&
              testcase
                ?.filter((item) => !item.isHidden)
                .map((testcase, index) => (
                  <div className="w-full">
                    <Disclosure
                      className={`border py-2 w-full mb-3 rounded-lg  px-3 ${
                        userTheme === "vs-dark" ? "text-gray-50" : ""
                      } ${
                        testcase.passed ? "border-green-500" : "border-red-500"
                      }`}
                    >
                      {({ open }) => (
                        <div className="mb-4 w-full">
                          <div
                            className={`flex w-full justify-between rounded-t-2xl border-b-2  border-opacity-50 border-gray-200  px-4 py-4 text-left text-sm font-medium   focus:outline-none ${
                              testcase.passed ? "" : ""
                            }`}
                          >
                            <div className="flex gap-2 w-full justify-between text-sm font-bold">
                              <div key={index} className="flex gap-2 w-full">
                                <div id={`output-${1}`}>
                                  {"Testcase " + (index + 1)}

                                  {/* {loading ? "Loading..." : userOutput} */}
                                  {testcase?.passed !== null && (
                                    // <span className="ml-2">
                                    //   {testcase.passed ? (
                                    //     <span role="img" aria-label="pass">
                                    //       ✅
                                    //     </span>
                                    //   ) : (
                                    //     <span role="img" aria-label="fail">
                                    //       ❌
                                    //     </span>
                                    //   )}
                                    // </span>
                                    <></>
                                  )}
                                </div>
                              </div>
                              <Disclosure.Button>
                                <FaCaretDown
                                  className={`${
                                    open ? "" : ""
                                  } h-5 w-5 text-[#8F92A1]`}
                                />
                              </Disclosure.Button>
                              {/* <CiSettings
                          className="w-5 h-5 text-gray-500 hover:cursor-pointer"
                          onClick={() => navigate("/collage/test/assessment")}
                        /> */}
                            </div>
                          </div>

                          {/* <Transition
                        enter=" "
                        enterFrom=""
                        enterTo=""
                        leave="transition duration-300 ease-out"
                        leaveFrom="transform scale-100  opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                      > */}
                          <Disclosure.Panel
                            className={`rounded-b-lg pb-6 mb-2 pt-4 text-sm text-gray-500 `}
                          >
                            Input :
                            <div className="w-full flex items-center">
                              <textarea
                                value={testcase.input}
                                className="pointer-events-none  bg-transparent border-none h-20"
                              />
                            </div>
                            <div className="w-full">
                              Output : {testcase.output}
                            </div>
                            <div className="w-full">
                              Expected Output : {testcase.expectedOutput}
                            </div>
                          </Disclosure.Panel>
                          {/* </Transition> */}
                        </div>
                      )}
                    </Disclosure>
                  </div>
                ))}
          </div>
          {/* // ) : ( // <SkeletonLoader />
          // )} */}
        </div>
      </div>
    </div>
  );
};

export default SlideUpDown;
