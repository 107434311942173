import React from "react";

const PrivacyPolicy = () => {
  return (
    <div className="bg-[#fff] min-h-screen">
      <div className="px-24 pt-10">
        <h1 className="font-bold text-[28px]">Terms & Policies</h1>
        <h2 className="text-lg font-bold pt-20">Terms and Conditions:</h2>
        <p className="pt-3">
          Welcome to our Skill Assessment Web App. These Terms and Conditions
          govern your use of the App. By accessing or using the App, you agree
          to be bound by these Terms and Conditions. If you do not agree with
          any part of these Terms and Conditions, you may not access or use the
          App.
        </p>
        <p className="pt-4">
          “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.””Lorem ipsum
          dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. “
        </p>
        <h2 className="text-lg font-bold pt-8">Privacy Policy:</h2>
        <p className="pt-3">
          We are committed to protecting your privacy. This Privacy Policy
          explains how we collect, use, and disclose information about you when
          you use the Skill Assessment Web App ("the App").
        </p>
        <p className="pt-4">
          “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
          eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad
          minim veniam, quis nostrud exercitation ullamco laboris nisi ut
          aliquip ex ea commodo consequat. Duis aute irure dolor in
          reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
          pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
          culpa qui officia deserunt mollit anim id est laborum.””Lorem ipsum
          dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
          incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam,
          quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea
          commodo consequat. “
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
