import React, { useEffect, useRef, useState } from "react";
import axios from "axios";
import Editor from "@monaco-editor/react";
import Select from "react-select";
import SlideUpDown from "./Slide";
import Skeleton from "../../../loaders/Skeleton";
import { TiTickOutline } from "react-icons/ti";
import { RxCross2 } from "react-icons/rx";
import {
  FaArrowRight,
  FaCaretLeft,
  FaCaretRight,
  FaChevronDown,
  FaChevronUp,
} from "react-icons/fa";
import { LoaderIcon, toast } from "react-hot-toast";
import {
  addLog,
  getLog,
} from "../../../../redux/student/test/thunks/assessment";
import { useDispatch, useSelector } from "react-redux";

const Code = ({
  logsState,
  setLogs,
  submission,
  setSubmission,
  testId,
  question,
  userLang,
  setUserLang,
  setAnswer,
  userCode,
  setUserCode,
  answer,
  isLoading,
  isCompiled,
  setIsCompiled,
  setCanSubmit,
  userInput,
  setUserInput,
}) => {
  const [log, setLog] = useState(``);
  const [testCaseResult, setTestCaseResult] = useState(0);
  // const [userCode, setUserCode] = useState(``);
  // const [userLang, setUserLang] = useState("Python");
  const [userTheme, setUserTheme] = useState("light");
  const [fontSize, setFontSize] = useState(20);

  const [userOutput, setUserOutput] = useState("");
  const [expectedOutput, setExpectedOutput] = useState("Expected Output");
  const [runLoading, setRunLoading] = useState(false);
  const [runTestLoading, setRunTestLoading] = useState(false);
  // const [submission, setSubmission] = useState(false);
  const [loading, setLoading] = useState(false);
  const [down, setDown] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const divRef = useRef(null);
  const editorRef = useRef(null);
  function convertToIST(utcDate) {
    const istDate = new Date(new Date(utcDate).getTime());
    const year = istDate.getFullYear();
    const month = String(istDate.getMonth() + 1).padStart(2, "0");
    const date = String(istDate.getDate()).padStart(2, "0");
    const hours = String(istDate.getHours()).padStart(2, "0");
    const minutes = String(istDate.getMinutes()).padStart(2, "0");
    const seconds = String(istDate.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${date} ${hours}:${minutes}:${seconds}`;
  }
  const dispatch = useDispatch();
  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleTransitionEnd = () => {
    if (!isOpen) {
      divRef.current.style.height = "0px";
    }
  };
  //   const [testPassed, setTestPassed] = useState(null); // null means not tested, true means passed, false means failed

  const options = {
    fontSize: fontSize,
    selectOnLineNumbers: true,
    automaticLayout: true, // Automatically adjust layout
    formatOnType: true, // Format code as you type
    formatOnPaste: true, // Format code when you paste
  };
  // const data = [
  //   {
  //     time: "2024-06-12 17:38:32",
  //     status: "Correct",
  //     user: "2/5",
  //     lang: "C++",
  //     code: "View",
  //   },
  //   {
  //     time: "2024-06-12 17:29:32",
  //     status: "Correct",
  //     user: "9/11",
  //     lang: "C++",
  //     code: "View",
  //   },
  // ];
  const { logs, submitTest } = useSelector((state) => state.studentTest);
  // const [logsState, setLogs] = useState([]);

  useEffect(() => {
    setLogs(logs);
  }, [logs]);

  const handleUserLang = (lang) => {
    if (lang === "Python" || lang === "Python3" || lang === "python") {
      return "py";
    } else if (lang === "C" || lang === "c") {
      return "c";
    } else if (lang === "Cpp" || lang === "C++" || lang === "cpp") {
      return "cpp";
    } else if (lang === "Java" || lang === "java") {
      return "java";
    }
  };

  function compileUserInput() {
    setRunLoading(true);

    if (!isOpen) {
      toggle();
    }
    let language = handleUserLang(userLang);

    return axios
      .post(
        `${process.env.REACT_APP_COMPILER_ROUTE}`,
        // `https://skillaccess-compiler-cev7pzkbea-uc.a.run.app/`,
        {
          code: answer?.code[userLang]?.answerCode,
          language: language,
          input: `${userInput}`,
        },
        {
          timeout: 20000,
        }
      )
      .then((res) => {
        setRunLoading(false);

        if (!res.data.output) {
          setUserOutput(res.data.error);
        } else {
          setUserOutput(res.data.output);
          setLog(res.data.output);
        }
      })
      .catch((err) => {
        setRunLoading(false);
        //console.log("Error", err);
        setUserOutput("Error Executing code");
      });
  }

  async function compile() {
    //console.log(question.testcase);
    setUserInput((prev) => {
      try {
        if (question?.testcase?.length > 0 && !prev) {
          return question.testcase[0].input;
        }
        return prev;
      } catch (error) {
        return prev;
      }
    });
    // //console.log("compiling");
    // //console.log(question);
    setLog("");
    setUserOutput("");
    if (!isOpen) {
      toggle();
    }
    if (runLoading) {
      return;
    }
    compileUserInput();
    // setAnswer({ ...answer, codeLanguage: userLang });
    // setLoading(true);
    // setRunLoading(true);
    // // setUserCode(answer?.code[userLang]?.answerCode);
    // let updatedTestcases = [...answer.testcase];
    // //console.log("userInput", userInput);

    // let language = handleUserLang(userLang);
    // // Construct the input for the API call
    // let input = `${question?.testcase.length}\n`; // Start with the number of test cases
    // question?.testcase.forEach((testcase) => {
    //   input += `${testcase.input}\n`; // Add each test case's input
    // });
    // // Make a single API call with the constructed input
    // //
    // // https://compiler-rf5q.onrender.com/

    // await axios
    //   .post(
    //     // `https://compiler-rf5q.onrender.com/`,
    //     `${process.env.REACT_APP_COMPILER_ROUTE}`,
    //     {
    //       code: answer?.code[userLang]?.answerCode,
    //       language: language,
    //       input: input.trim(),
    //     },
    //     {
    //       timeout: 20000,
    //     }
    //   )
    //   .then((res) => {
    //     setRunLoading(false);
    //     const output = res.data.output;

    //     if (!output) {
    //       let test = question.testcase.map((testcase) => {
    //         return { ...testcase, passed: false, output: "errror" };
    //       });

    //       // setAnswer({
    //       //   ...answer,
    //       //   testcase: test,
    //       //   codeLanguage: userLang,
    //       //   totalTestCases: 0,
    //       // });
    //       setLog(res.data.error);
    //     } else {
    //       // setLog(output);
    //       let test = question.testcase.map((testcase) => {
    //         return { ...testcase, passed: false };
    //       });
    //       let count = 0;
    //       const processedOutput = output.trim().split("\n");
    //       processedOutput.forEach((output, index) => {
    //         if (
    //           output.trim() ===
    //           question?.testcase?.[index]?.expectedOutput?.trim()
    //         ) {
    //           setTestCaseResult((prev) => prev + 1);
    //           test[index].passed = true;
    //           test[index].output = output;
    //           count += 1;
    //         } else {
    //           test[index].passed = false;
    //           test[index].output = output;
    //         }
    //       });
    //     }

    //     // setCanSubmit(true);
    //   })
    //   .catch((error) => {
    //     setLog("Code execution error");

    //     setRunLoading(false);
    //   });

    // return true;
  }

  async function compileAndTest() {
    // if (runTestLoading) {
    //   return;
    // }
    setLog("");
    
    setUserOutput("");
    if (!isOpen) {
      toggle();
    }

    // if (userInput) compileUserInput();
    setAnswer({ ...answer, codeLanguage: userLang });
    setLoading(true);
    setRunTestLoading(true);

    let updatedTestcases = [...answer.testcase];

    let language = handleUserLang(userLang);
    // Construct the input for the API call
    let input = `${question?.testcase.length}\n`; // Start with the number of test cases
    question?.testcase.forEach((testcase) => {
      input += `${testcase.input}\n`; // Add each test case's input
    });
    // Make a single API call with the constructed input
    //
    // https://compiler-rf5q.onrender.com/

    await axios
      .post(
        // `https://compiler-rf5q.onrender.com/`,

        `${process.env.REACT_APP_COMPILER_ROUTE}/test`,
        {
          code: answer?.code[userLang]?.answerCode,
          language: language,
          compilerId: question._id,
        },
        {
          timeout: 70000,
        }
      )
      .then((res) => {
        let output = [];
        let { passed, failed } = res.data;

        // Process passed cases
        passed.forEach((testCase) => {
          let obj = {
            ...testCase,
            output: testCase.realtimeOutputEscapeRemoved,
          };
          output.push(obj);
        });

        // Process failed cases if needed
        failed.forEach((testCase) => {
          let obj = {
            ...testCase,
            output: testCase.realtimeOutputEscapeRemoved,
          }; // Or any other modifications you need
          output.push(obj);
        });

        if (failed.length > 0) {
          for (let index = 0; index < failed.length; index++) {
            if (!failed[index].isHidden) {
              setLog(failed[index].error);
              break; // Exit the loop after setting the log for the first non-hidden case
            }
          }
        }

        if (!output || output.length < 1) {
          let test = question.testcase.map((testcase) => {
            return { ...testcase, passed: false, output: "errror" };
          });

          setAnswer({
            ...answer,
            testcase: test,
            codeLanguage: userLang,
            totalTestCases: 0,
          });
          setLog(res.data.error);
          dispatch(
            addLog({
              testId,
              data: {
                ...answer,
                testcase: test,
                codeLanguage: userLang,
                totalTestCases: 0,
                question: question._id,
              },
            })
          ).then(() => {
            dispatch(getLog({ testId, question: question._id }));
          });
        } else {
          // setLog(output);
          // let test = question.testcase.map((testcase) => {
          //   return { ...testcase, passed: false };
          // });
          let count = 0;

          setAnswer({
            ...answer,
            testcase: output,
            codeLanguage: userLang,
            totalTestCases: passed.length,
          });

          dispatch(
            addLog({
              testId,
              data: {
                ...answer,
                testcase: output,
                codeLanguage: userLang,
                totalTestCases: passed.length,
                question: question._id,
              },
            })
          ).then(() => {
            dispatch(getLog({ testId, question: question._id }));
          });
        }
      })
      .catch((error) => {
        setAnswer({
          ...answer,
          totalTestCases: 0,
        });
        dispatch(
          addLog({
            testId,
            data: {
              ...answer,
              testcase: question.testcase,
              codeLanguage: userLang,
              question: question._id,
            },
          })
        ).then(() => {
          dispatch(getLog({ testId, question: question._id }));
        });
        setLog("Code execution error");
        // setCanSubmit(true);
        setRunTestLoading(false);
        setRunLoading(false);
      });
    setRunTestLoading(false);
    return true;
  }

  function clearOutput() {
    setUserOutput("");
    // setTestPassed(null);
  }

  const themes = [
    { value: "vs-dark", label: "Dark" },
    { value: "light", label: "Light" },
  ];
  const testcases = question?.testcases || [];
  const initialLanguages = {
    ...(question?.code?.Python?.defaultCode && {
      Python: {
        defaultCode: question.code.Python.defaultCode,
        label: "Python",
      },
    }),
    ...(question?.code?.Java?.defaultCode && {
      Java: {
        defaultCode: question.code.Java.defaultCode,
        label: "Java",
      },
    }),
    ...(question?.code?.Cpp?.defaultCode && {
      Cpp: {
        defaultCode: question.code.Cpp.defaultCode,
        label: "C++",
      },
    }),
    ...(question?.code?.C?.defaultCode && {
      C: {
        defaultCode: question.code.C.defaultCode,
        label: "C",
      },
    }),
  };
  const languageOptions = Object.keys(initialLanguages).map((key) => ({
    value: key,
    label: initialLanguages[key].label,
  }));

  const panel = useRef(null);

  const [width, setWidth] = useState(
    panel?.current?.offsetWidth ? panel.current.offsetWidth : ""
  );

  useEffect(() => {
    dispatch(getLog({ testId, question: question._id }));
    document.addEventListener("mousemove", () => {
      try {
        if (panel?.current?.offsetWidth) {
          setWidth(panel.current.offsetWidth);
        }
      } catch (error) {}
    });
  }, [question._id]);

  const handleFullscreen = () => {
    const element = document.documentElement; // or any other element you want to make fullscreen
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.mozRequestFullScreen) {
      // Firefox
      element.mozRequestFullScreen();
    } else if (element.webkitRequestFullscreen) {
      // Chrome, Safari and Opera
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      // IE/Edge
      element.msRequestFullscreen();
    }
  };

  useEffect(() => {
    if (isCompiled) {
      compileAndTest().then(() => {
        setIsCompiled(true);
        setCanSubmit(true);
        toggle();
      });
    }
  }, [isCompiled]);

  useEffect(() => {
    if (isCompiled && submitTest) {
      compileAndTest().then(() => {
        setIsCompiled(true);
        setCanSubmit(true);
        toggle();
      });
    }
  }, [submitTest, isCompiled]);

  const onMount = (editor, monaco) => {
    editorRef.current = editor;
    editor.focus();

    // Disable right-click context menu
    editor.getDomNode().addEventListener(
      "contextmenu",
      (event) => {
        event.stopPropagation();
        event.preventDefault();
        toast.error("Right-click is not allowed.");
      },
      true // Capture phase
    );

    editor.onKeyDown((event) => {
      const { keyCode, ctrlKey, metaKey } = event;
      if ((keyCode === 33 || keyCode === 52) && (metaKey || ctrlKey)) {
        event.preventDefault();
      }
    });
  };

  return (
    <>
      {isLoading ? (
        <Skeleton />
      ) : (
        question &&
        question.codeQuestion && (
          <div
            className="flex flex-wrap gap-2 mx-auto  h-fit w-full p-2"
            onDrag={() => setWidth(panel.current.offsetWidth)}
          >
            {/* <button onClick={handleFullscreen}>Go Fullscreen</button> */}
            {/* <h2 className="text-2xl font-bold w-full">{question?.Title}</h2> */}

            <div
              className={`navbar w-full justify-between p-2 rounded-lg ${
                userTheme === "vs-dark" ? "bg-[#1e1e1e]" : "bg-white border-b-2"
              } `}
            >
              <div className="flex justify-between w-[600px]">
                <Select
                  options={languageOptions}
                  value={languageOptions.find(
                    (option) => option.value === userLang
                  )}
                  onChange={(e) => {
                    setUserLang(e.value);
                    setUserCode(initialLanguages[e.value].defaultCode);
                  }}
                  placeholder={userLang}
                  className="mr-2"
                />
                <Select
                  options={themes}
                  value={userTheme}
                  onChange={(e) => setUserTheme(e.value)}
                  placeholder={userTheme}
                  className="mr-2"
                />
                <label className="mr-2">Font Size</label>
                <input
                  type="range"
                  min="18"
                  max="30"
                  value={fontSize}
                  step="2"
                  onChange={(e) => {
                    setFontSize(e.target.value);
                  }}
                />

                <div className="">
                  {" "}
                  {!isOpen && (
                    <>
                      <button
                        onClick={() => toggle()}
                        className={`bg-[#D9E1E7] p-2 rounded-lg  ${
                          userTheme === "light" ? "text-black" : "text-white"
                        }`}
                      >
                        {" "}
                        <FaChevronDown />
                      </button>
                    </>
                  )}
                </div>
              </div>

              <div className="flex space-x-4">
                <p
                  className={`py-2 px-4  flex ml-6 cursor-pointer ${
                    submission ? " " : "border-b-blue-500 border-b-4  "
                  }
                ${userTheme === "vs-dark" ? "text-gray-200 " : ""}
                
                `}
                  onClick={() => setSubmission(false)}
                >
                  Code Editor
                </p>
                <p
                  className={`py-2 px-4 cursor-pointer  flex ml-6 ${
                    !submission ? " " : "border-b-blue-500 border-b-4  "
                  }
                ${userTheme === "vs-dark" ? "text-gray-200 " : ""}
                `}
                  onClick={() => setSubmission(true)}
                >
                  All Submissions
                </p>

                <button
                  className={`py-2 px-4 rounded-xl flex ml-6 items-center ${
                    userTheme === "vs-dark"
                      ? "bg-gray-200 "
                      : "bg-[#00875A] border border-gray-200 text-gray-200"
                  }`}
                  onClick={compile}
                >
                  Run{" "}
                  {runLoading && <LoaderIcon className="ml-2 animate-spin" />}
                </button>
                <button
                  className={`py-2 px-4 rounded-xl flex ml-6 items-center ${
                    userTheme === "vs-dark"
                      ? "bg-gray-200 "
                      : "bg-[#00875A] border border-gray-200 text-gray-200"
                  }`}
                  onClick={compileAndTest}
                >
                  Submit
                  {runTestLoading && (
                    <LoaderIcon className="ml-2 animate-spin" />
                  )}
                </button>
              </div>
            </div>
            <div className="w-full flex min-h-[65vh] h-full  ">
              {
                <div
                  className={` rounded-md resize-x relative break-words w-fit block pl-2 py-2 h-fit ${
                    userTheme === "vs-dark" ? "bg-[#1e1e1e]" : "bg-white"
                  }`}
                >
                  <div className="flex  items-start gap-2">
                    {" "}
                    {!isOpen && (
                      <p
                        className="min-h-[65vh] overflow-x-scroll scrollbar-thin pr-4 !scrollbar-track-black h-full border-r-8 border-gray-400 !scrollbar-thumb-white text-base first-letter:uppercase font-dmSans"
                        style={{
                          width: width || "500px",
                          color: `${
                            userTheme !== "vs-dark"
                              ? "#1e1e1e"
                              : "rgb(156 163 175)"
                          } `,
                        }}
                        dangerouslySetInnerHTML={{
                          __html: question.codeQuestion,
                        }}
                      />
                    )}
                  </div>

                  <div
                    className="resize-x overflow-hidden  w-[500px] h-5 flex justify-end absolute top-1/2 z-[999]"
                    ref={panel}
                  >
                    <div className=" rounded-full hover:cursor-pointer flex bg-white items-center ">
                      <FaCaretLeft />
                      <FaCaretRight />
                    </div>
                  </div>

                  <SlideUpDown
                    runTestLoading={runTestLoading}
                    runLoading={runLoading}
                    answer={answer}
                    testcase={answer.testcase}
                    testCaseResult={testCaseResult}
                    className="w-full"
                    userTheme={userTheme}
                    question={question}
                    userInput={userInput}
                    setUserInput={setUserInput}
                    userOutput={userOutput}
                    setUserOutput={setUserOutput}
                    expectedOutput={expectedOutput}
                    setExpectedOutput={setExpectedOutput}
                    loading={loading}
                    setLoading={setLoading}
                    log={log}
                    setLog={setLog}
                    isOpen={isOpen}
                    toggle={toggle}
                    handleTransitionEnd={handleTransitionEnd}
                    divRef={divRef}
                    setWidth={setWidth}
                    width={width}
                    panel={panel}
                    // testPassed={testPassed}
                  />
                </div>
              }

              {/* compiler */}
              <div className=" resize-x overflow-hidden scale-[1] h-[65vh] w-full">
                {!submission ? (
                  <Editor
                    className={`  min-h-[calc(70vh - 50px)] text-base ${
                      userTheme === "vs-dark" ? "bg-[#1e1e1e]" : "bg-white"
                    }`}
                    options={options}
                    height="100%"
                    width="100%"
                    theme={userTheme}
                    language={userLang.toLocaleLowerCase()}
                    defaultLanguage={userLang.toLowerCase()}
                    defaultValue={userCode || "//please select a language"}
                    value={answer?.code[userLang]?.answerCode || userCode}
                    onChange={(value) => {
                      // if (regexForValidation.test(value)) {
                      setAnswer({
                        ...answer,
                        code: {
                          ...answer?.code,
                          [userLang]: { answerCode: value },
                        },
                      });

                      // } else {
                      // toast.error("You can't edit this area");
                      // }
                    }}
                    onMount={onMount}
                  />
                ) : (
                  <div className="overflow-x-auto">
                    {logsState?.length > 0 ? (
                      <table className="min-w-full bg-white border border-gray-300">
                        <thead>
                          <tr>
                            <th className="py-2 px-4 border-b">Time (IST)</th>
                            <th className="py-2 px-4 border-b">Status</th>
                            <th className="py-2 px-4 border-b">Testcases</th>
                            <th className="py-2 px-4 border-b">Lang</th>
                            {/* <th className="py-2 px-4 border-b">Code</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {logsState?.map((row, index) => (
                            <tr key={index}>
                              <td className="text-center py-2 px-4 border-b">
                                {convertToIST(row.createdAt)}
                              </td>
                              <td
                                className={` text-center  py-2 px-4 border-b ${
                                  row.totalTestCases === row.testcase.length
                                    ? "text-green-500"
                                    : " text-red-500"
                                }`}
                              >
                                <div className="flex justify-center items-center">
                                  {row.totalTestCases ===
                                  row.testcase.length ? (
                                    <TiTickOutline className="text-2xl font-extrabold" />
                                  ) : (
                                    <RxCross2 className="text-2xl font-extrabold" />
                                  )}
                                </div>
                              </td>
                              <td className="text-center py-2 px-4 border-b">
                                {row.totalTestCases + "/" + row.testcase.length}
                              </td>
                              <td className="text-center py-2 px-4 border-b">
                                {row.codeLanguage}
                              </td>
                              {/* <td className="py-2 px-4 border-b text-blue-500 cursor-pointer">
              {row.code}
            </td> */}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <div className="text-center text-xl font-bold py-16 text-red-500 ">
                        No submission found
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* <Split>
        <div className="w-1/2 h-10 bg-secondary"></div>
        <div className="w-1/2 h-10 bg-red-500"></div>
      </Split> */}
          </div>
        )
      )}
    </>
  );
};

export default Code;
