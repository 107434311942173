import React from "react";
import { useDispatch } from "react-redux";

const FullscreenPrompt = ({ handleEnterFullscreen, handleCancel }) => {
  const dispatch = useDispatch();
  const [confirmCancel, setConfirmCancel] = React.useState(false);
  const [endTest, setEndTest] = React.useState(false);

  const handleConfirmCancel = () => {
    setConfirmCancel(true);
  };

  React.useEffect(() => {
    if (endTest) {
      handleCancel();
    }
  }, [endTest]);

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-75 z-50">
      <div className="bg-white shadow-md w-80 h-44 mx-auto rounded-lg p-6 flex flex-col justify-center gap-4">
        {!confirmCancel && (
          <>
            <h1 className="text-center text-lg font-semibold">
              Continue Test in Fullscreen
            </h1>
            <p className="text-center text-sm">
              To continue the test, please enter fullscreen mode.
            </p>
            <div className="flex justify-between">
              <button
                className="bg-gray-200 text-gray-700 hover:bg-gray-300 border border-gray-300 py-2 px-4 rounded-md text-sm font-medium focus:outline-none"
                onClick={handleConfirmCancel}
              >
                Cancel
              </button>
              <button
                className="bg-accent text-white hover:bg-accent border border-blue-600 py-2 px-4 rounded-md text-sm font-medium focus:outline-none"
                onClick={handleEnterFullscreen}
              >
                Enter Fullscreen
              </button>
            </div>
          </>
        )}
        {confirmCancel && (
          <div className="warning-text text-red-500 text-center text-sm">
            Are you sure you want to cancel the test? You'll lose one attempt.
            <div className="flex justify-center gap-4 mt-4">
              <button
                className="bg-gray-200 text-gray-700 hover:bg-gray-300 border border-gray-300 py-2 px-4 rounded-md text-sm font-medium focus:outline-none"
                onClick={() => {
                  setConfirmCancel(false);
                }}
              >
                No
              </button>
              <button
                className="bg-red-600 text-white hover:bg-red-700 border border-red-600 py-2 px-4 rounded-md text-sm font-medium focus:outline-none"
                onClick={() => {
                  setEndTest(true);
                  handleCancel();
                }}
              >
                Yes
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default FullscreenPrompt;
