import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axios from "axios";

const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

export const getMail = createAsyncThunk(
  "studentInbox/getMail",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.get(
        `${REACT_APP_API_URL}/api/student/inbox/getMails?skip=${data.skip}&limit=${data.limit}`,

        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;

      return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const searchMail = createAsyncThunk(
  "studentInbox/searchMail",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/inbox/search`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const sendReply = createAsyncThunk(
  "studentInbox/sendReply",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/inbox/reply`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const uploadAttachment = createAsyncThunk(
  "studentInbox/uploadAttachment",
  async (data, { rejectWithValue }) => {
    try {
      let formData = new FormData();
      data.forEach((file, index) => {
        formData.append(`file${index}`, file);
      });

      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/inbox/file`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;

      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const sendMail = createAsyncThunk(
  "studentInbox/sendMail",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/inbox/sendMail`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;

      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const deleteMail = createAsyncThunk(
  "studentInbox/deleteMail",
  async (data, { rejectWithValue }) => {
    try {
      const req = await axios.delete(
        `${REACT_APP_API_URL}/api/student/inbox/delete/${data.id}/${data.type}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const bookmarkMail = createAsyncThunk(
  "studentInbox/bookmarkMail",
  async (mailId, { rejectWithValue }) => {
    try {
      const req = await axios.post(
        `${REACT_APP_API_URL}/api/student/inbox/bookmark/${mailId}`,
        {},
        {
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
          },
          withCredentials: true,
        }
      );
      const res = req.data;
      return res.data;
    } catch (error) {
      //console.log("catch");
      return rejectWithValue(error.response.data);
    }
  }
);

export const removeBookmarkedMail = createAsyncThunk(
  "studentInbox/removeBookmarkMail",
  async (mailId, { rejectWithValue }) => {
    try {
      const req = await axios.delete(
        `${REACT_APP_API_URL}/api/student/inbox/bookmark/${mailId}`,

        {
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
          },
          withCredentials: true,
        }
      );
      const res = req.data;
      return res.data;
    } catch (error) {
      //console.log("catch");
      return rejectWithValue(error.response.data);
    }
  }
);

export const markAsRead = createAsyncThunk(
  "inbox/markAsRead",
  async (data, { rejectWithValue, dispatch }) => {
    try {
      const req = await axios.put(
        `${REACT_APP_API_URL}/api/student/inbox/seen/${data.id}?type=${data.type}`,
        {},
        {
          headers: {
            "auth-token": localStorage.getItem("auth-token"),
          },
          withCredentials: true,
        }
      );
      const res = req.data;
      dispatch(getMail());

      return res.data;
    } catch (error) {
      //console.log("catch");
      return rejectWithValue(error.response.data);
    }
  }
);
