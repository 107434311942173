import React from "react";
import { FaWindowMinimize, FaCaretDown } from "react-icons/fa";

const SkeletonLoader = ({ userTheme }) => {
  return (
    <div className="animate-pulse p-4 gap-2">
      <div className="">
        <div className="w-3/4 py-6">
          <div
            className={`h-[100px] rounded-lg p-1 w-full border border-slate-300 focus:ring-0 mb-2 ${
              userTheme === "vs-dark" ? "bg-gray-200" : "bg-gray-300"
            }`}
          ></div>
        </div>
        <div className="w-3/4 py-6">
          <div
            className={`h-[100px] rounded-lg p-1 w-full border border-slate-300 focus:ring-0 mb-2 ${
              userTheme === "vs-dark" ? "bg-gray-200" : "bg-gray-300"
            }`}
          ></div>
        </div>
      </div>

      <div className="w-full mt-4">
        <div
          className={`border py-2 w-full mb-3 rounded-lg px-3 ${
            userTheme === "vs-dark" ? "text-gray-50" : ""
          } ${"border-gray-300"}`}
        >
          <div className="flex justify-between">
            <span className="bg-gray-200 h-6 w-20 rounded-md"></span>
            {/* <FaCaretDown className="h-5 w-5 text-gray-500" /> */}
          </div>
          <div className={`rounded-b-lg pb-6 mb-2 pt-4 text-sm text-gray-500`}>
            <div className="w-full">
              <span className="bg-gray-200 h-4 w-full block rounded-md mb-2"></span>
              <span className="bg-gray-200 h-4 w-full block rounded-md mb-2"></span>
              <span className="bg-gray-200 h-4 w-full block rounded-md mb-2"></span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkeletonLoader;
