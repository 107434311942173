import React, { useEffect, useRef, useState,useCallback } from "react";
import { Link, useNavigate, useLocation, useSearchParams, useParams } from "react-router-dom";
import Navbar from "../components/navbar/StudentNavbar";
import {
  setSelected,
  selected,
  selectedStudent,
} from "../redux/student/sidebar/sideSlice";
import { useDispatch, useSelector } from "react-redux";
// import {
//   setTestBasicDetails,
//   setTestSelectedTopics,
// } from "../redux/collage/test/testSlice";
import TestHeader from "../components/student/test/start/TestHeader";
import { toast } from "react-hot-toast";
import { uploadScreenshot } from "../redux/student/test/thunks/assessment";

const StudentTestLayout = ({ children }) => {
  const navigate = useNavigate();

  const selection = useSelector(selectedStudent);

  // const {currentTopicIndex , topicNames } = useSelector((state) => state.studentTest);
  const currentTopicIndex = localStorage.getItem("currentTopicIndex");
  const topicNames = JSON.parse(localStorage.getItem("topicNames"));

  // //console.log(currentTopicIndex, topicNames);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(true);
  const [down, setDown] = useState(0);
  const location = useLocation();
  const bottom = useRef(null);

  let  sensitivity =25, checkInterval = 3000, warningThreshold = 5;

  const {id} = useParams();
  const [screenshot, setScreenshot] = useState(null);
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [status, setStatus] = useState('Initializing...');
  const [isUserPresent, setIsUserPresent] = useState(true);
  const warningCountRef = useRef(0);

  useEffect(() => {
   
    const intervalId = setInterval(takeSnapshot, 600_000); // 10000 ms = 10 seconds

    // Cleanup function to clear the interval on unmount
    return () => clearInterval(intervalId);
  }, []); // Empty dependency array to run on mount and unmount


  const startVideo = useCallback(async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ video: true });
      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }

    } catch (err) {
      console.error("Error accessing the camera:", err);
      setStatus("Error: Unable to access camera");
      toast.error("Unable to access camera. Please check your camera settings.");
    }
  }, []);

  useEffect(() => {
    startVideo();
    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        videoRef.current.srcObject.getTracks().forEach(track => track.stop());
      }
    };
  }, [startVideo]);

  const pixelDiff = useCallback((prev, curr) => {
    let diff = 0;
    for (let i = 0; i < prev.length; i += 4) {
      diff += Math.abs(prev[i] - curr[i]);
    }
    return diff / (prev.length / 4); // Average difference per pixel
  }, []);

  const detectMotion = useCallback(() => {
    const video = videoRef.current;
    const canvas = canvasRef.current;
    if (!video || !canvas) return;

    const context = canvas.getContext('2d', { willReadFrequently: true });
    context.drawImage(video, 0, 0, canvas.width, canvas.height);
    const currentImageData = context.getImageData(0, 0, canvas.width, canvas.height);

    if (canvas.previousImageData) {
      const diff = pixelDiff(canvas.previousImageData.data, currentImageData.data);
      
      if (diff > sensitivity) {
        warningCountRef.current += 1;
        if (warningCountRef.current >= warningThreshold) {
          setIsUserPresent(false);
          setStatus("Warning: Excessive movement detected");
          toast.error("You're moving too much. This may lead to disqualification.");
        }
      } else {
        warningCountRef.current = Math.max(0, warningCountRef.current - 1);
        if (warningCountRef.current === 0) {
          setIsUserPresent(true);
          setStatus("You're under observation");
          // toast.success("You're under observation");
        }
      }
    }

    canvas.previousImageData = currentImageData;
  }, [sensitivity, pixelDiff]);

  useEffect(() => {
    let detectionInterval = null;

    if (videoRef.current) {
      videoRef.current.onloadedmetadata = () => {
        canvasRef.current.width = videoRef.current.videoWidth;
        canvasRef.current.height = videoRef.current.videoHeight;
        detectionInterval = setInterval(detectMotion, checkInterval);
      };
    }

    return () => {
      if (detectionInterval) {
        clearInterval(detectionInterval);
      }
    };
  }, [checkInterval, detectMotion]);

  

  const takeSnapshot = async  () => {
   try {
    console.log("taking shot")
    const canvas = canvasRef.current;
    const video = videoRef.current;

    if (canvas && video) {
      const context = canvas.getContext('2d');
      canvas.width = video.videoWidth; // Set canvas width to video width
      canvas.height = video.videoHeight; // Set canvas height to video height

      // Draw the current video frame onto the canvas
      context.drawImage(video, 0, 0, canvas.width, canvas.height);

      // Get the image data URL
      const imageDataUrl = canvas.toDataURL('image/png');
      setScreenshot(imageDataUrl);
     
     await dispatch(uploadScreenshot({assessmentId:id,base64Image:imageDataUrl}));
    }
   } catch (error) {
    console.log(error)
   }
  };

  let arr = topicNames.map((el, i) => {
    return {
      name: el,
      index: i,
      path: `/student/test/section/${i + 1}`,
      icon: (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="21"
          height="21"
          viewBox="0 0 21 21"
          fill="none"
        >
          <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M2.64642 7.4993H7.5779V2.4998H2.64642V7.4993ZM1.66012 9.5C1.11504 9.5 0.673828 9.0518 0.673828 8.50009V1.49989C0.673828 0.94729 1.11504 0.5 1.66012 0.5H8.56508C9.10928 0.5 9.55138 0.9473 9.55138 1.49989V8.50009C9.55138 9.0518 9.10928 9.5 8.56508 9.5H1.66012ZM13.4966 7.4993H18.4289V2.4998H13.4966V7.4993ZM12.5103 9.5C11.9652 9.5 11.524 9.0518 11.524 8.50009V1.49989C11.524 0.94729 11.9652 0.5 12.5103 0.5H19.4152C19.9594 0.5 20.4015 0.9473 20.4015 1.49989V8.50009C20.4015 9.0518 19.9594 9.5 19.4152 9.5H12.5103ZM2.64642 18.5H7.5779V13.4996H2.64642V18.5ZM1.66012 20.4998C1.11504 20.4998 0.673828 20.0516 0.673828 19.4999V12.4997C0.673828 11.9471 1.11504 11.4998 1.66012 11.4998H8.56508C9.10928 11.4998 9.55138 11.9471 9.55138 12.4997V19.4999C9.55138 20.0516 9.10928 20.4998 8.56508 20.4998H1.66012ZM13.4966 18.5H18.4289V13.4996H13.4966V18.5ZM12.5103 20.4998C11.9652 20.4998 11.524 20.0516 11.524 19.4999V12.4997C11.524 11.9471 11.9652 11.4998 12.5103 11.4998H19.4152C19.9594 11.4998 20.4015 11.9471 20.4015 12.4997V19.4999C20.4015 20.0516 19.9594 20.4998 19.4152 20.4998H12.5103Z"
            fill={`${selection === 0 ? "#171717" : "white"}`}
          />
        </svg>
      ),
    };
  });

  return (
    <>
      <Navbar open={open} setOpen={setOpen} />
      <TestHeader />

      {/* <div className={`mt-4 p-2 ${isUserPresent ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'}`}>
        Status: {status}
      </div> */}
      <div className="h-full bg-secondary relative ">
        <div className="flex h-screen justify-start pt-10">
          <aside
            className={` px-2 sm:px-4 block transition-width overflow-x-hidden bg-secondary z-30 scrollbar overflow-y-scroll ${
              open ? "w-[260px]" : "w-[69px]"
            }`}
            style={{ marginTop: "1rem" }}
          >
            {" "}
            {/* ---------------video start--------------- */}

            <div className=" video" style={{ display: open ? "block" : "none" }}>
              <video
                ref={videoRef}
                autoPlay
                playsInline
                muted
                className=" w-48  p-2 max-w-lg border border-gray-300 rounded-lg  "
              />
              <canvas ref={canvasRef} className="hidden" />
            </div>

            {/* ---------------video end--------------- */}


            <ul className="list-none">
              {arr.map((el, i) => {
                return (
                  <>
                    <li>
                      <button
                        onClick={() => {
                          setOpen(!open);
                        }}
                        className={` ml-[-10px] sm:ml-[5px] flex  mb-10  h-fit  py-2 justify-start ${
                          el.name === "Notifications"
                            ? "ml-[-10px] hidden"
                            : "btn "
                        }
                     ${
                       open ? "w-full " : " "
                     }   shadow-none text-white rounded-xl  border-none  mt-2 focus:outline-none  max-w-xs hover:bg-white hover:text-black mx-auto 
                     ${
                       currentTopicIndex === el.index
                         ? "bg-white !text-black w-[15rem]"
                         : "bg-secondary"
                     }
                     hover:w-[15rem]`}
                      >
                        <div className="flex items-center gap-4">
                          <div className="w-3 ml-[-5px] hover:text-black">
                            {el.icon}
                          </div>

                          {open && (
                            <h3 className="text-left leading-5">{el.name}</h3>
                          )}

                          {/* <h3>{el.name}</h3> */}
                        </div>
                      </button>
                    </li>
                  </>
                );
              })}
            </ul>
          </aside>

          <div
            // className="bg-white  min-h-[75vh] h-full w-full p-5 mx-4 ml-14 lg:ml-60 mt-6  font-dmSans mr-5 rounded-xl"
            className="bg-white rounded-3xl h-[75vh] overflow-y-scroll w-full flex-1 font-dmSans mr-5 p-5"
          >
            {children}

          </div>
        </div>
      </div>
    </>
  );
};

export default StudentTestLayout;
