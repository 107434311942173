const utcTimeString = "2024-04-08T08:42:19.391Z";
const utcDate = new Date(utcTimeString);

// Convert UTC time to a specific time zone (e.g., 'Asia/Kolkata')

// Output: "4/8/2024, 2:12:19 PM"
// Function to get the day with suffix (e.g., "1st", "2nd", "3rd", "4th", etc.)
const getDayWithSuffix = (day) => {
  const suffixes = ["th", "st", "nd", "rd"];
  const suffixIndex =
    day % 10 <= 3 && day % 10 > 0 && day / 10 !== 1 ? day % 10 : 0;
  return day + suffixes[suffixIndex];
};

const convertDateFormat = (time) => {
  const utcDate = new Date(time);
  const timeZone = "Asia/Kolkata";
  const localDate = utcDate.toLocaleString("en-US", { timeZone });
  // //console.log(localDate);
  return localDate.split(",")[0];
};

export const convertToDDMM = (time) => {
  const utcDate = new Date(time);
  const timeZone = "Asia/Kolkata";
  const options = {
    day: "2-digit",
    month: "long",
    year: "numeric",
    timeZone,
  };
  const localDate = utcDate.toLocaleDateString("en-US", options);
  // Splitting the formatted date to get day, month, and year separately
  const [month, day, year] = localDate.split(" ");

  // Combining the day with suffix, month, and year in the desired format
  const formattedDate = `${getDayWithSuffix(
    day.substring(0, day.length - 1)
  )} ${month} `;

  return formattedDate;
};

export default convertDateFormat;
