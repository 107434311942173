import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { FiBell } from "react-icons/fi";
import { useSelector } from "react-redux";
import { FaCoins } from "react-icons/fa";
import useTranslate from "../../hooks/useTranslate";
import GoogleTranslate from "../GoogleTranslate";

const Navbar = (props) => {
  const navigate = useNavigate();
  const currentLanguage = useTranslate();

  const goToProfile = () => {
    navigate("/student/profile"); // Use navigate function to navigate to desired URL
  };
  const userDetails = useSelector((state) => state.studentAuth);

  return (
    <div className="border border-y-1 sorder border-gray-400 bg-white w-full z-[9999] m-0 fixed top-0">
      <div className="navbar flex justify-between ">
        {/* left */}
        <div>
          {/* mobile only */}
          <button
            className="btn btn-primary sm:hidden  "
            onClick={() => props.setOpen(!props.open)}
          >
            hamb
          </button>

          <div className="ml-3">
            {" "}
            <img src="../../../images/logoFinal.png" alt="" width="180px" />
            </div>
        </div>

        {/* right */}
        <div className="flex gap-4">
          {/* <button className="border-2 border-[#D9E1E7]  text-[#D9E1E7] rounded-lg p-1 px-2 relative">
            <FiBell className="text-lg" />{" "}
            <div className="rounded-full h-2 w-2 bg-lightBlue  absolute top-1 right-2"></div>
          </button> */}

          {/* noifiaction */}
          <button
            className="border border-[#D9E1E7]  rounded-lg p-2 relative flex gap-3 "
            style={{ marginRight: "12rem" }}
            onClick={goToProfile}
          >
            <img
              src={userDetails?.user?.avatar?.url}
              alt=""
              className="h-5 w-5"
            />{" "}
            <h2 className="text-sm font-bold self-center font-dmSans">
              Hello {userDetails?.user?.FirstName}
            </h2>
          </button>
          {/* noifiaction */}

          {/* noifiaction */}
          {/* <button className="border-none   rounded-lg p-2 relative"> */}
          {/* <div className="border-2 border-gray-400  text-gray-400 rounded-lg p-1 relative">
            <div className="rounded-full h-7 w-7 bg-gradient-to-b from-red-600 to-slate-800 "
            style={{backgroundImage: `url(${languages[language].image})`}}
            >
              <img src={languages[language].image} alt="" className="h-7 w-7" />
            </div>
          </div> */}

          {/* <FaAngleDown className="" /> */}
          {/* <div id="google_translate_element" className="google-div"></div> */}
          <GoogleTranslate currentLanguage={currentLanguage} />
          {/* <select
            
              className="border-none bg-transparent"
              onChange={(e) => changeLanguage(e.target.value)}
            >
              {languages.map((lang, index) => (
                <option key={index} value={index}>
                  <img src={lang.image} alt="" className="w-6 h-6" />
                  {lang.name}
                </option>
              ))}
            </select>

          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Navbar;
