import React from 'react'

const Skeleton = () => {
  return (
    <div className="mx-6 flex bg-[#F8F8F9] rounded-lg justify-between my-4 animate-pulse">
    <div className="w-11/12 flex flex-col gap-2">
      <div className="flex px-4 gap-3 font-semibold pt-3 text-base w-full">
        <div className="w-4 h-4 bg-gray-300 rounded"></div>
        <div className="w-full bg-gray-300 h-6 rounded"></div>
        <div className="w-12 bg-gray-300 h-4 rounded self-end"></div>
      </div>
      <div className="px-5 pb-4 flex flex-col gap-4 pt-2">
        {Array(4).fill(0).map((_, idx) => (
          <span key={idx} className="flex gap-2">
            <div className="flex w-5 justify-center">
              <div className="w-3 h-3 bg-gray-300 rounded-full"></div>
            </div>
            <div className="w-full bg-gray-300 h-4 rounded"></div>
          </span>
        ))}
      </div>
    </div>
  </div>
  )
}

export default Skeleton