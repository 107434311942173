import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Define the async thunk
export const fetchStudentResults = createAsyncThunk(
  "results/fetchStudentResults",
  async (studentId, { rejectWithValue }) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/studentDummy/test/result`,
        { params: { studentId } }
      );
      return response.data.studentResponse;
    } catch (error) {
      return rejectWithValue(error.response?.data || error.message);
    }
  }
);

// Define the slice
const resultSlice = createSlice({
  name: "results",
  initialState: {
    data: [],
    status: "idle",
    error: null,
    FETCH_RESULT_LOADING :false,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStudentResults.pending, (state) => {
        state.status = "loading";
        state.FETCH_RESULT_LOADING = true;
      })
      .addCase(fetchStudentResults.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.FETCH_RESULT_LOADING = false;
        state.data = action.payload;
      })
      .addCase(fetchStudentResults.rejected, (state, action) => {
        state.status = "failed";
        state.FETCH_RESULT_LOADING = false;
        state.error = action.payload;
      });
  },
});

// Export the reducer to be used in the store
export default resultSlice.reducer;
