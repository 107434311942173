import { Skeleton } from "@mui/material";
import React from "react";

const Questions = ({
  question,
  answerIndex,
  setAnswerIndex,
  count,
  loading,
}) => {
  const handleChange = (e) => {
    setAnswerIndex(e.target.value);
  };

  // //console.log(question);

  // //console.log(question.QuestionLevel);
  return (
    <>
      {loading ? (
        <div className="flex bg-[#F8F8F9] rounded-lg gap-5 animate-pulse">
          <div className="w-11/12 flex flex-col gap-2">
            <div className="flex px-4 gap-3 font-semibold pt-3 text-base w-full">
              <div className="w-4 h-4 bg-gray-300 rounded"></div>
              <div className="w-full bg-gray-300 h-6 rounded"></div>
              <div className="w-12 bg-gray-300 h-4 rounded self-end"></div>
            </div>
            <div className="px-5 pb-4 flex flex-col gap-4 pt-2">
              {Array(4)
                .fill(0)
                .map((_, idx) => (
                  <span key={idx} className="flex gap-2">
                    <div className="flex w-5 justify-center">
                      <div className="w-3 h-3 bg-gray-300 rounded-full"></div>
                    </div>
                    <div className="w-full bg-gray-300 h-4 rounded"></div>
                  </span>
                ))}
            </div>
          </div>
        </div>
      ) : (
        question && (
          <div className="flex bg-[#F8F8F9] rounded-lg justify-between">
            <div className="w-11/12 flex flex-col gap-2 p-5">
              {/* {search.get(`${Number}`) !== "true" ? ( */}
              <div className="flex  gap-1  w-full font-semibold text-base text-[#171717]">
                <h2> Q{count}.</h2>
                <h2 dangerouslySetInnerHTML={{ __html: question.Title }}></h2>?
                {/* <div className="self-end">{question?.QuestionLevel}</div> */}
              </div>

              <div className="flex flex-col gap-3">
                <div className="flex gap-2">
                  <div className="flex w-5 justify-center">
                    <input
                      onChange={handleChange}
                      checked={parseInt(answerIndex) === 0}
                      name="option"
                      value={0}
                      type="radio"
                      id="answer-0"
                      className="w-3 h-3 p-[.4rem] checked:bg-none  checked:border checked:border-blue-700 border-secondary checked:p-0 border-2  ring-transparent ring-2 checked:ring-blue-700 ring-offset-2   self-center "
                    />{" "}
                  </div>
                  <label htmlFor="answer-0" className="self-center text-base">
                    {question?.Options[0]}
                  </label>
                </div>
                <div className="flex gap-2">
                  <div className="flex w-5 justify-center">
                    <input
                      onChange={handleChange}
                      name="option"
                      type="radio"
                      value={1}
                      checked={parseInt(answerIndex) === 1}
                      id="answer-1"
                      className="w-3 h-3 p-[.4rem] checked:bg-none  checked:border checked:border-blue-700 border-secondary checked:p-0 border-2  ring-transparent ring-2 checked:ring-blue-700 ring-offset-2   self-center "
                    />{" "}
                  </div>
                  <label htmlFor="answer-1" className="self-center text-base">
                    {question?.Options[1]}
                  </label>
                </div>
                <div className="flex gap-2">
                  <div className="flex w-5 justify-center">
                    <input
                      onChange={handleChange}
                      name="option"
                      checked={parseInt(answerIndex) === 2}
                      value={2}
                      type="radio"
                      id="answer-2"
                      className="w-3 h-3 p-[.4rem] checked:bg-none  checked:border checked:border-blue-700 border-secondary checked:p-0 border-2  ring-transparent ring-2 checked:ring-blue-700 ring-offset-2   self-center "
                    />{" "}
                  </div>
                  <label htmlFor="answer-2" className="self-center text-base">
                    {question?.Options[2]}
                  </label>
                </div>
                <div className="flex gap-2">
                  <div className="flex w-5 justify-center">
                    <input
                      onChange={handleChange}
                      name="option"
                      value={3}
                      checked={parseInt(answerIndex) === 3}
                      type="radio"
                      id="answer-3"
                      className="w-3 h-3 p-[.4rem] checked:bg-none  checked:border checked:border-blue-700 border-secondary checked:p-0 border-2  ring-transparent ring-2 checked:ring-blue-700 ring-offset-2   self-center "
                    />{" "}
                  </div>
                  <label htmlFor="answer-3" className="self-center text-base">
                    {question?.Options[3]}
                  </label>
                </div>
              </div>
            </div>
          </div>
        )
      )}
    </>
  );
};

export default Questions;
