import { createAsyncThunk } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import axios from "axios";

import { getAssessment } from "../../../student/dashboard/dashboardSlice";
import dataURItoBlob from "../../../../util/dataUriToBlob";

export const getAllAssessments = createAsyncThunk(
  "studentTest/getAllAssessments",
  async (data, { rejectWithValue }) => {
    //console.log(data.status);
    try {
      const req = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/student/test?status=${data?.status}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data.assessments;
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getAssessmentById = createAsyncThunk(
  "studentTest/getAssessmentById",
  async (testId, { rejectWithValue }) => {
    //console.log(process.env.REACT_APP_API_URL);
    try {
      const req = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/student/test/${testId}`,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data.assessment;
      //console.log(res);
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const sendResponse = createAsyncThunk(
  "studentTest/sendResponse",
  async (data, { rejectWithValue }) => {
    const authToken = localStorage.getItem("auth-token");
    //console.log(data, "data");
    if (!authToken) {
      return rejectWithValue("Auth token not found");
    }

    try {
      const req = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/student/test/response/${data.testId}`,
        { response: data.response || -1, timeTaken: data.timeTaken || 0 }, // Send response data in the request body
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const sendResponseNonAdaptive = createAsyncThunk(
  "studentTest/sendResponseNonAdaptive",
  async (data, { rejectWithValue }) => {
    const authToken = localStorage.getItem("auth-token");
    if (!authToken) {
      return rejectWithValue("Auth token not found");
    }

    try {
      const req = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/student/test/response/non-adaptive/${data.testId}`,
        { response: data.response }, // Send response data in the request body
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": authToken,
          },
        }
      );
      const res = req.data;
      return res;
    } catch (error) {
      //console.log(error);
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const startTest = createAsyncThunk(
  "studentTest/startTest",
  async ({ testId, isAdaptive }, { rejectWithValue }) => {
    //console.log(localStorage.getItem("auth-token"));
    try {
      const req = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/student/test/start/${testId}?adaptive=${isAdaptive}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      if (res.success === false) {
        return rejectWithValue(res.message);
      }
      return { ...res, testId, isAdaptive };

      // return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

// ----------------------------------------- END TEST -----------------------------------------

export const endTest = createAsyncThunk(
  "studentTest/endTest",
  async ({ testId, isAdaptive }, { rejectWithValue }) => {
    //console.log(localStorage.getItem("auth-token"));
    try {
      const req = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/student/test/end/${testId}?adaptive=${isAdaptive}`,
        { timeTaken: JSON.parse(localStorage.getItem("timeTaken")) },
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;
      // if (res.success === false) {
      //   return rejectWithValue(res.message);
      // }
      localStorage.removeItem("timeTaken");
      return res;

      // return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const addLog = createAsyncThunk(
  "studentTest/addLog",
  async ({ testId, data }, { rejectWithValue }) => {
    //console.log(localStorage.getItem("auth-token"));
    try {
      const req = await axios.post(
        `${process.env.REACT_APP_API_URL}/api/compiler/log/${testId}`,
        data,
        {
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;

      return res;

      // return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const getLog = createAsyncThunk(
  "studentTest/addLog",
  async ({ testId, question }, { rejectWithValue }) => {
    try {
      const req = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/compiler/log/${testId}?question=${question}`,
        {
          // params: {
          //   question: question,
          // },
          headers: {
            "Content-Type": "application/json",
            "auth-token": localStorage.getItem("auth-token"),
          },
        }
      );
      const res = req.data;

      return res;
    } catch (error) {
      return rejectWithValue(error.response.data.message);
    }
  }
);

export const uploadScreenshot = createAsyncThunk(
  "studentTest/uploadScreenshot",
  async ({ assessmentId,base64Image}, { rejectWithValue }) => {
    try {

      const formData = new FormData();
      console.log(base64Image)
      formData.append('screenshot', dataURItoBlob(base64Image));
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/student/test/${assessmentId}/screenshot`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          "auth-token": localStorage.getItem("auth-token"),
        },
      });
      
      const screenshotLog = response.data;

      return screenshotLog
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response.data.message);
    }
  }
);

